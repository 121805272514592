import React from "react";

import {p} from "@pg-design/helpers-css";
import {ICoreModalProps, Modal} from "@pg-design/modal";

interface IProps extends ICoreModalProps {
    children: React.ReactNode;
    modalHeading: string;
    onModalClose: () => void;
}

export const MobileOptionsModal: React.FC<IProps> = (props) => {
    return (
        <Modal isOpen={props.isOpen} onModalClose={props.onModalClose}>
            <Modal.Header variant="bar_mini">{props.modalHeading}</Modal.Header>
            <Modal.Content css={p(0)} className={props.className}>
                {props.children}
            </Modal.Content>
        </Modal>
    );
};
