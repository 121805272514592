import {combineReducers} from "redux";

import {IRange} from "@pg-design/inputs";
import {createFormActions, createFormActionTypes, FormFieldType, reduceFormValues} from "@pg-mono/form";
import {reduceResponse} from "@pg-mono/request-state";

import {fromJsonValues} from "../../form/translate/from_json_values";
import {fetchAllPropertiesCountTypes} from "../actions/fetch_all_properties_count_action";
import {Floor} from "../types/Floor";
import {KitchenType} from "../types/KitchenType";
import {FormAdditionalAreaType, FormRoomType} from "../types/RoomType";

export const searchFormFields = {
    search: FormFieldType.Input,
    type: FormFieldType.SearchOfferType,
    rooms: FormFieldType.SelectRangeNumber,
    price: FormFieldType.InputRange,
    area: FormFieldType.InputRange,
    floor_choices: FormFieldType.MultiCheckbox,
    construction_end_date: FormFieldType.MultiCheckbox,
    distance: FormFieldType.Input,
    rooms_area_type_selected: FormFieldType.MultiCheckbox,
    kitchen_type: FormFieldType.MultiCheckbox,
    selected_room: FormFieldType.Select,
    floors: FormFieldType.MultiCheckbox,
    world_directions: FormFieldType.MultiCheckbox,
    loan: FormFieldType.Checkbox,
    aftermarket: FormFieldType.Checkbox,
    house_type: FormFieldType.Radio,
    house_additional_areas: FormFieldType.MultiCheckbox,
    house_storeys: FormFieldType.Radio
};

export interface ISearchFiltersFormValues {
    rooms: IRange<number | "">;
    area: IRange<string>;
    selected_room: FormRoomType | null;
    rooms_area_type_selected: (FormRoomType | FormAdditionalAreaType)[];
    floors: Floor[];
    world_directions: string[];
    price: IRange<string | "">;
    construction_end_date: number[];
    loan: boolean;
    aftermarket: boolean;
    kitchen_type: KitchenType[];
}

const search_actions = "property_configurator/SEARCH_FORM";
export const searchFormTypes = createFormActionTypes(search_actions);
export interface IPropertyConfiguratorStore {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formValues: Record<keyof typeof searchFormFields, any>;
    propertiesCount: null | number;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducer: Record<keyof IPropertyConfiguratorStore, any> = {
    propertiesCount: reduceResponse(fetchAllPropertiesCountTypes),
    formValues: reduceFormValues(
        searchFormTypes,
        fromJsonValues(searchFormFields, {
            floors: [],
            construction_end_date: [],
            distance: 5,
            rooms_area_type_selected: [],
            kitchen_type: [],
            loan: false,
            aftermarket: false,
            price: {
                lower: "",
                upper: "500000"
            },
            world_directions: []
        })
    )
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const searchFormActions = createFormActions<Record<keyof typeof searchFormFields, any>>(searchFormTypes);

export const propertyConfiguratorStore = combineReducers(reducer);
