import {Dispatch} from "redux";

import {createRequestActionTypes} from "@pg-mono/request-state";

import {IRPStore} from "../../app/rp_reducer";
import {fetchAllPropertiesCount} from "../api/fetch_all_properties_count";
import {getParams} from "../utils/get_property_api_params";

const FETCH_ALL_PROPERTIES_COUNT = "property_configurator/FETCH_ALL_PROPERTIES_COUNT";
export const fetchAllPropertiesCountTypes = createRequestActionTypes(FETCH_ALL_PROPERTIES_COUNT);

export const fetchAllPropertiesCountAction = (disablePriceFiltering: boolean) => async (dispatch: Dispatch, getState: () => IRPStore) => {
    dispatch({type: fetchAllPropertiesCountTypes.start});

    const result = await fetchAllPropertiesCount(getParams(getState().propertyConfigurator.formValues, 1), disablePriceFiltering);

    if (typeof result === "number") {
        return dispatch({type: fetchAllPropertiesCountTypes.success, result});
    }
};
